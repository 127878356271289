// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]6.1-10651(f808560b26)-C30/09/2024-18:11:46-B30/09/2024-18:16:07' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]6.1-10651(f808560b26)-C30/09/2024-18:11:46-B30/09/2024-18:16:07",
  branch: "master",
  latestTag: "6.1",
  revCount: "10651",
  shortHash: "f808560b26",
  longHash: "f808560b269df949c3c0a72500ac7883ce1f9270",
  dateCommit: "30/09/2024-18:11:46",
  dateBuild: "30/09/2024-18:16:07",
  buildType: "Ansible",
  } ;
